<template>
  <vm-table
    ref="vmTable"
    v-loading="loading"
    :filter.sync="search"
    url="maintItemDetail">
    <template slot="adSearch">
      <vm-search :label="$l('maintWorkOrder.workCondition','工作情况')">
        <el-select
          v-model="search.workCondition"
          :placeholder="$t('common.pleaseSelect')"
          clearable>
          <el-option
            v-for="item in workConditionOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"></el-option>
        </el-select>
      </vm-search>
      <vm-search :label="$l('maintWorkOrder.maintArea','保养区域')">
        <el-select
          v-model="search.maintArea"
          :placeholder="$t('common.pleaseSelect')"
          clearable>
          <el-option
            v-for="item in maintAreaOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"></el-option>
        </el-select>
      </vm-search>
    </template>
    <el-table-column prop="maintContent" :label="$l('maintWorkOrder.maintContent','保养内容')"></el-table-column>
    <el-table-column prop="maintArea" :label="$l('maintWorkOrder.maintArea','保养区域')" width="100px" align="center">
      <template v-slot="scope">
        <span v-if="scope.row.maintArea === '机房'">
          {{$l("maintWorkOrder.maintenanceArea.machineRoom","机房")}}
        </span>
        <span v-else-if="scope.row.maintArea === '轿厢'">
          {{$l("maintWorkOrder.maintenanceArea.bridge","轿厢")}}
        </span>
        <span v-else-if="scope.row.maintArea === '层站'">
          {{$l("maintWorkOrder.maintenanceArea.layer","层站")}}
        </span>
        <span v-else-if="scope.row.maintArea === '井道及底坑'">
          {{$l("maintWorkOrder.maintenanceArea.wellRoadAndTheBottomPit","井道及底坑")}}
        </span>
        <span v-else-if="scope.row.maintArea === '扶梯'">
          {{$l("maintWorkOrder.maintenanceArea.escalator","扶梯")}}
        </span>
        <span v-else-if="scope.row.maintArea === '其他'">
          {{$l("maintWorkOrder.maintenanceArea.other","其他")}}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="workCondition" :label="$l('maintWorkOrder.workCondition','工作情况')" align="center" :width="this.$l('200px','100px')">
      <template v-slot="scope">
        <el-tag v-if="scope.row.workCondition === null" type="info">
          {{$l("maintWorkOrder.condition.notProcessed","未处理")}}
        </el-tag>
        <el-tag v-else-if="scope.row.workCondition === 0" type="warning">
          {{$l("maintWorkOrder.condition.noSuchItem","无此项")}}
        </el-tag>
        <el-tag v-else-if="scope.row.workCondition === 1" type="success">
          {{$l("maintWorkOrder.condition.qualified","合格")}}
        </el-tag>
        <el-tag v-else-if="scope.row.workCondition === 2">
          {{$l("maintWorkOrder.condition.qualifiedAfterAdjustment","调整后合格")}}
        </el-tag>
        <el-tag v-else-if="scope.row.workCondition === 3" type="danger">
          {{$l("maintWorkOrder.condition.unqualified","不合格")}}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="situationDescription" :label="$l('maintWorkOrder.situationDescription','现场情况描述')"></el-table-column>
    <el-table-column prop="picture" :label="$l('maintWorkOrder.picture','现场情况照片')" align="center" width="100px">
      <template v-slot="scope">
        <el-image
          v-if="scope.row.picture && scope.row.picture.includes('uploadFiles')"
          style="width: 25px; height: auto"
          :src="handleOldPhoto(scope.row)"
          fit="contain"
          lazy
          :preview-src-list="[handleOldPhoto(scope.row)]">
        </el-image>
        <el-image
          v-else-if="scope.row.picture"
          style="width: 25px; height: auto"
          :src="scope.row.picture"
          fit="contain"
          lazy
          :preview-src-list="[scope.row.picture]">
        </el-image>
      </template>
    </el-table-column>
    <el-table-column prop="soundRecord" :label="$l('maintWorkOrder.soundRecord','现场情况录音')" align="center">
      <template v-slot="scope">
        <audio
          v-if="scope.row.soundRecord"
          style="width: 100%; height: 30px"
          :src="scope.row.soundRecord"
          controls="controls">
        </audio>
      </template>
    </el-table-column>
    <el-table-column prop="processTime" :label="$l('maintWorkOrder.processTime','处理时间')" width="150px" align="center"></el-table-column>
  </vm-table>
</template>

<script>
  export default {
    components: {},
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        loading: true,
        search: {
          id: this.id,
          maintArea: "",
          workCondition: "",
        },
        maintAreaOption: [
          {value: "机房", label: this.$l("maintWorkOrder.maintenanceArea.machineRoom","机房")},
          {value: "轿厢", label: this.$l("maintWorkOrder.maintenanceArea.bridge","轿厢")},
          {value: "层站", label: this.$l("maintWorkOrder.maintenanceArea.layer","层站")},
          {value: "井道及底坑", label: this.$l("maintWorkOrder.maintenanceArea.wellRoadAndTheBottomPit","井道及底坑")},
          {value: "扶梯", label: this.$l("maintWorkOrder.maintenanceArea.escalator","扶梯")},
          {value: "其他", label: this.$l("maintWorkOrder.maintenanceArea.other","其他")},
        ],
        workConditionOption: [
          {value: 0, label: this.$l("maintWorkOrder.condition.noSuchItem","无此项")},
          {value: 1, label: this.$l("maintWorkOrder.condition.qualified","合格")},
          {value: 2, label: this.$l("maintWorkOrder.condition.qualifiedAfterAdjustment","调整后合格")},
          {value: 3, label: this.$l("maintWorkOrder.condition.unqualified","不合格")},
        ],
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.loading = false;
      },
      handleOldPhoto(row) {
        return window.config.SERVER_URL + row.picture;
      },
    },
  };
</script>

<style scoped>

</style>