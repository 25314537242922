<template>
  <div class="body22">
    <div class="back">
      <div class="logo"><img class="large" :src="$tenant.bigLogo" alt="" style="width: 227px; height: 34px"/></div>
      <div class="content">
        <p style="font-size: 12px;color: #404040;margin: 10px 0 10px 10px">{{$l("maintWorkOrder.currentLocation","当前位置 > 保养工单")}}</p>
        <div class="vm-separate" style="border: solid 1px #D63B3F;background-color: #FBEBEB;">
          <div style="margin-left:10px;margin-right:10px;line-height: 50px">
            <span
              style="font-size: 20px;font-weight: 600;color: #333333">{{$l("maintWorkOrder.orderNum","工单编号")}}：{{maintWorkOrder.orderNum}}
            </span>
          </div>
          <div style="line-height: 50px">
            <span style="font-size: 20px;font-weight: 600;color: #333333"> {{$l("maintWorkOrder.maintStatus","工单状态")}}：
              <span v-if="maintWorkOrder.maintStatus === 0" style="color: #333333">
                {{$l("maintWorkOrder.invalid","作废")}}
              </span>
              <span v-if="maintWorkOrder.maintStatus === 1" style="color: #333333">
                {{$l("maintWorkOrder.inPlan","计划中")}}
              </span>
              <span v-if="maintWorkOrder.maintStatus === 2" style="color: #333333">
                {{$l("maintWorkOrder.notAccepted","未接受")}}
              </span>
              <span v-if="maintWorkOrder.maintStatus === 3" style="color: #333333">
                {{$l("maintWorkOrder.accepted","已接受")}}
              </span>
              <span v-if="maintWorkOrder.maintStatus === 4" style="color: #333333">
                {{$l("maintWorkOrder.signedIn","已签到")}}
              </span>
              <span v-if="maintWorkOrder.maintStatus === 5" style="color: #333333">
                {{$l("maintWorkOrder.completed","已完成")}}
              </span>
            </span>
          </div>
        </div>
        <el-row>
          <ul class="vm-separate" style="margin: 20px 0;">
            <li>
              <span v-if="maintWorkOrder.maintStatus !== 0">
                <p class="title">{{$l("maintWorkOrder.created","已创建")}}</p>
                <p><img src="/static/images/workOrder/yi_bao_jing.png" alt=""/></p>
                <p class="date">{{maintWorkOrder.createTime}}</p>
              </span>
              <span v-else>
                <p class="title">{{$l("maintWorkOrder.invalid","已作废")}}</p>
                <p><img src="/static/images/workOrder/yi_jie_jing.png" alt=""/></p>
              </span>
            </li>
            <li>
              <span v-if="maintWorkOrder.maintStatus > 1">
                <p class="title">{{$l("maintWorkOrder.send","已发送")}}</p>
                <p><img src="/static/images/workOrder/yi_dao_chang.png" alt=""/></p>
              </span>
              <span v-else>
                <p class="title">{{$l("maintWorkOrder.notSend","未发送")}}</p>
                <p><img src="/static/images/workOrder/daochang_b.png" alt=""/></p>
              </span>
            </li>
            <li>
              <span v-if="maintWorkOrder.maintStatus > 2">
                <p class="title">{{$l("maintWorkOrder.accepted","已接受")}}</p>
                <p><img src="/static/images/workOrder/yi_jie_jing.png" alt=""/></p>
                <p class="date">{{maintWorkOrder.acceptanceTime}}</p>
              </span>
              <span v-else>
                <p class="title">{{$l("maintWorkOrder.notAccepted","未接受")}}</p>
                <p><img src="/static/images/workOrder/jiejing_b.png" alt=""/></p>
              </span>
            </li>
            <li>
              <span v-if="maintWorkOrder.maintStatus > 3">
                <p class="title">{{$l("maintWorkOrder.signedIn","已签到")}}</p>
                <p><img src="/static/images/workOrder/yi_wan_gong.png" alt=""/></p>
                <p class="date">{{maintWorkOrder.checkinTime}}</p>
              </span>
              <span v-else>
                <p class="title">{{$l("maintWorkOrder.notSignIn","未签到")}}</p>
                <p><img src="/static/images/workOrder/wangong_b.png" alt=""/></p>
              </span>
            </li>
            <li>
              <span v-if="maintWorkOrder.maintStatus > 4">
                <p class="title">{{$l("maintWorkOrder.completed","已完成")}}</p>
                <p><img src="/static/images/workOrder/yi_que_ren.png" alt=""/></p>
                <p class="date">{{maintWorkOrder.completionTime}}</p>
              </span>
              <span v-else>
                <p class="title">{{$l("maintWorkOrder.notCompleted","未完成")}}</p>
                <p><img src="/static/images/workOrder/queren_b.png" alt=""/></p>
              </span>
            </li>
          </ul>
        </el-row>
        <div style="border: solid 1px #DCDFE6;">
          <div style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;">
            <span style="margin: 10px;line-height: 30px;font-weight: 600">{{$l("maintWorkOrder.maintBaseInfo","工单信息")}}</span>
          </div>
          <div style="margin: 0 20px 0 20px">
            <div style="margin: 10px;">
              <span
                style="line-height: 25px;font-weight: 600;font-size: 15px;color: #2B80DA;">{{$l("maintWorkOrder.maintWorkOrder","保养工单")}}</span>
              <div class="vm-separate">
                <span style="margin-top: 5px">{{$l("maintWorkOrder.creator","建单人")}}：{{maintWorkOrder.creator}}</span>
                <span>{{$l("maintWorkOrder.maintDate","保养日期")}}：{{maintWorkOrder.maintDate}}</span>
              </div>
              <div class="vm-separate">
                <span>{{$l("maintWorkOrder.maintainerName1","维保工1")}}：{{maintWorkOrder.maintainerName1}}</span>
                <span>{{$l("maintWorkOrder.maintainerName2","维保工2")}}：{{maintWorkOrder.maintainerName2}}</span>
              </div>
              <div class="vm-separate">
                <span>{{$l("maintWorkOrder.maintTypeName","保养类型名称")}}：{{maintWorkOrder.maintTypeName}}</span>
                <span>{{$l("maintWorkOrder.remark","备注")}}：{{maintWorkOrder.remark}}</span>
              </div>
            </div>
          </div>
        </div>
        <div style="border: solid 1px #DCDFE6;margin-top: 20px">
          <div style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;display: flex;justify-content: space-between">
            <span
              style="margin: 5px 10px;font-weight: 600;color: #2B80DA;cursor:pointer;"
              @click="display2 = changeDisplay(display2)">{{$l("maintWorkOrder.maintItem","保养项目")}}
              <i v-if="display2 === 'block'" class="el-icon-arrow-down"></i>
              <i v-else class="el-icon-arrow-up"></i>
            </span>
          </div>
          <div :style="{display: display2}">
            <maint-item-detail :id="$route.params.maintWorkOrderId"></maint-item-detail>
          </div>
        </div>
        <div v-if="maintWorkOrder.maintLifeCycles.length > 0" style="border: solid 1px #DCDFE6;margin-top: 20px;">
          <div style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;">
            <span
              style="margin: 10px;line-height: 30px;font-weight: 600;color: #2B80DA;cursor:pointer;"
              @click="display1 = changeDisplay(display1)">
              {{$l("maintWorkOrder.treatmentProcess","处理过程")}}
              <i v-if="display1 === 'block'" class="el-icon-arrow-down"></i>
              <i v-else class="el-icon-arrow-up"></i>
            </span>
          </div>
          <div :style="{display: display1}" style="max-height: 450px;overflow-y: scroll">
            <el-timeline style="margin-top: 5px">
              <el-timeline-item
                v-for="(maintLifeCycle, index) in maintWorkOrder.maintLifeCycles"
                :key="index"
                :timestamp="maintLifeCycle.createTime"
                placement="top">
                <span v-if="maintLifeCycle.record !== '系统自动创建工单'">{{maintLifeCycle.creator}}</span>
                <span v-if="maintLifeCycle.record.includes('image')">
                  {{" " + maintLifeCycle.record.substring(0, maintLifeCycle.record.indexOf("http"))}}
                  <el-image
                    style="max-width:80px;height:auto"
                    :src="maintLifeCycle.record.substring(maintLifeCycle.record.indexOf('http'), maintLifeCycle.record.length)"
                    fit="contain"
                    lazy
                    :preview-src-list="[maintLifeCycle.record.substring(maintLifeCycle.record.indexOf('http'), maintLifeCycle.record.length)]">
                  </el-image>
                </span>
                <span v-else-if="maintLifeCycle.creatorId === -1">
                  {{$l("maintWorkOrder.uploadPicture", "上传现场照片")}}
                  <el-image
                    style="max-width:50px;height:auto"
                    :src="handleOldPhoto(maintLifeCycle.record)"
                    fit="contain"
                    lazy
                    :preview-src-list="[handleOldPhoto(maintLifeCycle.record)]">
                  </el-image>
                </span>
                <span v-else-if="maintLifeCycle.record.includes('file')">
                  {{" " + maintLifeCycle.record.substring(0, maintLifeCycle.record.indexOf("http"))}}
                  <audio
                    style="width: 30%; height: 20px"
                    :src="maintLifeCycle.record.substring(maintLifeCycle.record.indexOf('http'), maintLifeCycle.record.length)"
                    controls="controls">
                  </audio>
                </span>
                <span v-else style="white-space: pre-wrap">{{" " + maintLifeCycle.record}}</span>
                <div style="height: 10px"></div>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
        <div v-if="maintWorkOrder.maintStatus === 5" style="border: solid 1px #DCDFE6;margin-top: 20px;">
          <div style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;">
            <span
              style="margin: 10px;line-height: 30px;font-weight: 600;color: #2B80DA;cursor:pointer;"
              @click="display3 = changeDisplay(display3)">
              {{$l("maintWorkOrder.sigAndReturnVisit","客户签字及回访")}}
              <i v-if="display3 === 'block'" class="el-icon-arrow-down"></i>
              <i v-else class="el-icon-arrow-up"></i>
            </span>
          </div>
          <div :style="{display: display3}" style="margin: 10px">
            <el-form :model="maintWorkOrder" label-width="140px">
              <div class="vm-separate">
                <el-form-item :label="$l('maintWorkOrder.evaluation','保养质量服务评价：')" :label-width="this.$l('270px','140px')">
                  <el-radio-group v-model="maintWorkOrder.satisfaction" style="margin-top: 5px" :disabled="true">
                    <el-radio :label="1">{{$l("maintWorkOrder.dissatisfied","不满意")}}</el-radio>
                    <el-radio :label="2">{{$l("maintWorkOrder.commonly","一般")}}</el-radio>
                    <el-radio :label="3">{{$l("maintWorkOrder.satisfied","满意")}}</el-radio>
                    <el-radio :label="4">{{$l("maintWorkOrder.verySatisfied","非常满意")}}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item :label="$l('maintWorkOrder.customerSignature','客户签字：')">
                  <el-image
                    v-if="maintWorkOrder.customerSign"
                    style="max-width:80px;height:auto"
                    :src="maintWorkOrder.customerSign"
                    fit="contain"
                    lazy
                    :preview-src-list="[maintWorkOrder.customerSign]">
                  </el-image>
                </el-form-item>
              </div>
              <el-form-item :label="$l('maintWorkOrder.advice','客户意见：')">
                <el-input
                  v-if="maintWorkOrder.advice"
                  v-model="maintWorkOrder.advice"
                  style="width: 70%"
                  type="textarea"
                  :readonly="true">
                </el-input>
              </el-form-item>
              <el-form-item :label="$l('maintWorkOrder.returnRecord','回访记录：')">
                <el-input
                  v-model="maintWorkOrder.returnVisitRecord"
                  style="width: 70%"
                  type="textarea"
                  :rows="5">
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit">{{$t("common.save")}}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">

    </div>
  </div>
</template>

<script>
  const moduleName = "maintenanceOrder";
  import MaintItemDetail from "@/views/newMaintWorkOrder/MaintItemDetailList";

  export default {
    components: {MaintItemDetail},
    data() {
      return {
        copyright: window.config.copyright,
        display1: "block",
        display2: "block",
        display3: "block",
        maintWorkOrder: {
          id: 0,
          maintStatus: "",
          maintLifeCycles: [],
          satisfaction: 0,
          advice: "",
          customerSign: "",
          returnVisitRecord: "",
        },
      };
    },
    mounted() {
      this.maintWorkOrder.id = this.$route.params.maintWorkOrderId;
      this.getData();
    },
    methods: {
      getData() {
        this.$api.getById(moduleName, this.maintWorkOrder.id).then(res => {
          this.maintWorkOrder = res.data;
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      changeDisplay(e) {
        if (e === "block") {
          e = "none";
        } else {
          e = "block";
        }
        return e;
      },
      onSubmit() {
        this.$http.put(moduleName, this.maintWorkOrder).then(() => {
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.saveError"));
          }
        });
      },
      handleOldPhoto(record) {
        return window.config.SERVER_URL + record;
      },
    },
  };
</script>
<style lang="scss" scoped>

.body22 {
  background-color: #404040;
  height: 100%;
  overflow-y:scroll;
  .back {
    width: 100%;
    .logo {
      width: 1090px;
      background-color: #404040;
      margin: 0 auto;
      padding: 10px 0;
    }
    .content {
      width: 1090px;
      background-color: white;
      margin:0 auto;
      border: solid 1px #DCDFE6;
      padding-bottom: 50px;
    }
  }
  .footer {
    color: #FFF;
    background-color: #404040;
    position: fixed;
    text-align: center;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 100;
  }
}

span {
  color: #606266;
}

.vm-separate {
  font-size: 12px;
  margin-bottom: 6px;
  margin-left: 6px;
  margin-right: 6px;
}

ul {
  white-space: nowrap;
  width: 92%;
}

li {
  list-style-type: none;
  display: inline;
}

p {
  margin: 0;
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #5a9d11;
  margin-left: -10px;
}

.date {
  font-size: 12px;
  color: #666666;
  text-align: center;
  margin-top: 5px;
}
</style>
